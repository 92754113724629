import { useUser } from "../../common/root-context";
import LeadsSummary from "../leads/leads-summary";
import UserProfileForm from "../user/user-profile-form";
import OrgDashboard from "./org-dashboard";
import SupervisorDashboard from "./sup-dashboard";
import SystemDashboard from "./system-dashboard";

export default function Dashboard() {
  const user = useUser();
  return user?.IsSuperAdmin || user?.IsAdmin ? (
    <SystemDashboard />
  ) : user?.IsManager ? (
    <OrgDashboard />
  ) : user?.IsSupervisor ? (
    <SupervisorDashboard />
  ) : (
    <UserProfileForm userId={user?.Id} />
  );
}
