import { PropsWithChildren } from "react";
import { useUser } from "../common/root-context";

export default function Auth({
  roles,
  children,
}: PropsWithChildren<AuthProps>) {
  const hasRequiredRoles = useUserHasRole(roles);
  //   if (roles.length) {
  //     if (user?.Roles && user?.Roles?.find((x) => !!roles.find((y) => y === x))) {
  //       return <>{children}</>;
  //     }
  //     return <></>;
  //   }
  return hasRequiredRoles ? <>{children}</> : <></>;
}

export type AuthProps = {
  roles: string[];
};

export function useUserHasRole(roles: string[]) {
  const user = useUser();
  if (roles.length) {
    return !!(
      user?.Roles && user?.Roles?.find((x) => !!roles.find((y) => y === x))
    );
  }
  return true;
}
