import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import {
  LockOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Modal, Space, Spin } from "antd";
import { Layout, Menu, theme } from "antd";
import {
  useNavigate,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import Dashboard from "./features/dashboard/dashboard";
import Organizations from "./features/organization/organizations";
import UserForm from "./features/user/user-form";
import UsersAll from "./features/user/users-all";
import UserProfileForm from "./features/user/user-profile-form";
import CardPlanForm from "./features/card/card-plan-form";
import useFetch from "./common/hooks/use-fetch";
import { loggedInState, useRoot, useUser } from "./common/root-context";
import Login from "./features/auth/login-form";
import UserSettings from "./features/user/settings";
import OrganizationForm from "./features/organization/organization-form";
import ConfigEmail from "./features/auth/config-email-form";
import SetPasswordForm from "./features/auth/set-password-form";
import ViewProfile from "./features/profile/view";
import UserLeads from "./features/leads/user-leads";
import RequestDiscountForm from "./features/discount-redeem/request-discount-form";
import Vendors from "./features/discount-vendor/vendors";
import VendorForm from "./features/discount-vendor/vendor-form";
import DealForm from "./features/discount-deal/deal-form";
import Deals from "./features/discount-deal/deals";
import ViewDeals from "./features/discount-redeem/view-deals";
import RedeemDeal from "./features/discount-redeem/redeem-deal";
import AuthLayout from "./layouts/auth-layout";
import FpEmail from "./features/auth/forgot-password/email-form";
import ResetPasswordForm from "./features/auth/forgot-password/reset-password";
import AppMenu from "./common/menu";
import BasePathRedirect from "./components/BasePathRedirect";
import ChangePasswordForm from "./features/user/change-password-form";
import ViewThemeForm from "./features/profile/view-theme-form";

const { Header, Content, Sider } = Layout;

// const items2: MenuProps["items"] = [
//   UserOutlined,
//   LaptopOutlined,
//   NotificationOutlined,
// ].map((icon, index) => {
//   const key = String(index + 1);

//   return {
//     key: `sub${key}`,
//     icon: React.createElement(icon),
//     label: `subnav ${key}`,

//     children: new Array(4).fill(null).map((_, j) => {
//       const subKey = index * 4 + j + 1;
//       return {
//         key: subKey,
//         label: `option${subKey}`,
//         path: `option${subKey}`,
//       };
//     }),
//   };
// });

const App: React.FC = () => {
  const root = useRoot();
  return (
    <>
      <Spin spinning={!!root?.blocked} tip="Loading...">
        <Routes>
          <Route path="/profile/:id" element={<ViewProfile />} />
          <Route path="/vendor/:vendorId" element={<ViewDeals />} />
          <Route path="/redeem-deal/:id" element={<RedeemDeal />} />
          <Route
            path="*"
            element={
              <>
                <AuthenticatedTemplate />
                <UnAuthenticatedTemplate />
              </>
            }
          />
        </Routes>
      </Spin>
    </>
  );
};

function RoutesWrapper(props: PropsWithChildren) {
  const user = useUser();
  const root = useRoot();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 576);
  const menuItem = useMenuItems();
  const userMenuItems: MenuProps['items'] = [
    {
      label: user?.Email,
      key: 'UserMenu',
      icon: <UserOutlined />,
      children: [
        {
          label: 'Change Password',
          key: 'changepassword',
          icon: <LockOutlined />,
          onClick: () => navigate("/change-password"),
        },
        {
          label: 'Logout',
          key: 'logout',
          icon: <LogoutOutlined />,
          onClick: () => root?.logout(),
        },
      ],
    }
  ];

  return (
    <Layout>
      <Header className="header">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="org-name">{user?.OrganizationName}</div>
          <Menu
            mode="horizontal"
            theme="dark"
            selectable={false}
            style={{ justifyContent: "flex-end", minWidth: 150, flex: "auto" }}
            items={userMenuItems}
          >
          </Menu>
        </div>
      </Header>
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={200}
          style={{ background: colorBgContainer }}
        >
          <Menu
            mode="inline"
            style={{ height: "100%", borderRight: 0 }}
            onClick={(item) => navigate(item.key)}
            items={menuItem}
          />
        </Sider>
        <Layout style={{ padding: "24px" }}>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              overflow: "initial",
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/organizations" element={<Organizations />} />
              <Route path="/organization/form" element={<OrganizationForm />} />
              <Route path="/org/form" element={<OrganizationForm />} />
              <Route path="/user-form" element={<UserForm />} />
              <Route path="/users" element={<UsersAll />} />
              <Route path="/user/settings" element={<UserSettings />} />
              <Route path="/user-profile" element={<UserProfileForm />} />
              <Route path="/leads" element={<UserLeads />} />
              <Route path="/vendors/all" element={<Vendors />} />
              <Route path="/vendors/form" element={<VendorForm />} />
              <Route path="/deals/all" element={<Deals />} />
              <Route path="/deals/form" element={<DealForm />} />
              <Route
                path="/my-profile"
                element={<UserProfileForm userId={user?.Id} />}
              />
              <Route path="/card-plan" element={<CardPlanForm />} />
              <Route path="/change-password" element={<ChangePasswordForm />} />
              <Route path="/profile-design" element={<ViewThemeForm />} />
              {/* <Route path="/profile/:id" element={<ViewProfile />} /> */}
            </Routes>
            <ConfirmModel />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

// function DefaultRoute() {
//   const user = useUser();
//   return user?.IsAdmin || user?.IsSuperAdmin ? (
//     <Navigate to="/dashboard" />
//   ) : user?.IsManager || user?.IsSupervisor ? (
//     <Navigate to="/dashboard" />
//   ) : (
//     <Navigate to="/my-profile" />
//   );
// }
function ViewCardRoute() {
  return <Route path="/profile/:id" element={<ViewProfile />} />;
}

function UnAuthenticatedTemplate(props: PropsWithChildren) {
  const root = useRoot();
  return root?.loggedInStatus === null ||
    root?.loggedInStatus === loggedInState.failed ? (
    <AuthLayout>
      <Routes>
        <Route path="/" element={<BasePathRedirect />} />
        <Route path="/login" element={<BasePathRedirect />} />
        <Route path=":orgSlug" element={<LoginNavigate />} />
        <Route path=":orgSlug/login" element={<Login />} />
        <Route path=":orgSlug/configure-email" element={<ConfigEmail />} />
        <Route path=":orgSlug/set-password" element={<SetPasswordForm />} />
        <Route path=":orgSlug/forgot-password" element={<FpEmail />} />
        <Route path=":orgSlug/reset-password" element={<ResetPasswordForm />} />
        {/* <Route path="/profile/:id" element={<ViewProfile />} /> */}
      </Routes>
    </AuthLayout>
  ) : (
    <></>
  );
}

function LoginNavigate() {
  const { orgSlug } = useParams();
  return <Navigate to={`/${orgSlug}/login`} />;
}

function ConfirmModel(props: PropsWithChildren) {
  const root = useRoot();
  const con = root?.confirmation;
  return (
    <Modal
      title="Confirmation"
      style={{ top: 20 }}
      open={!!con?.message}
      onOk={() => {
        con?.onOk();
        root?.setConfirmation(undefined);
      }}
      onCancel={() => root?.setConfirmation(undefined)}
    >
      <p>{con?.message}</p>
    </Modal>
  );
}

function AuthenticatedTemplate(props: PropsWithChildren) {
  const root = useRoot();
  const { get } = useFetch();
  useEffect(
    function () {
      if (root?.loggedInStatus === loggedInState.success) {
        (async function () {
          const user = await get("/auth/user");
          root?.setUser(user);
        })();
      }
    },
    [root?.loggedInStatus]
  );
  return root?.user &&
    root.user.Id &&
    root.loggedInStatus === loggedInState.success ? (
    <RoutesWrapper />
  ) : (
    <></>
  );
}

function useMenuItems() {
  const user = useUser();
  return useMemo(
    function () {
      return AppMenu.filter(
        (item) =>
          !item.roles?.length ||
          !!item.roles?.find((x: string) => user?.Roles.find((y) => y === x))
      );
    },
    [user]
  );
}

export default App;
