import { ChangeEvent, useEffect, useState } from "react";
import { useUser } from "../../common/root-context";
import AppDataTable from "../../components/data-table/app-data-table";
import useFetch from "../../common/hooks/use-fetch";
import { ColumnsType } from "antd/es/table";
import useQuery from "../../common/hooks/use-query";
import { toLocalDateTime } from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { Card, Col, Input, Row, Space } from "antd";

export default function Vendors() {
  const user = useUser();
  const [data, setData] = useState<VendorType[]>([]);
  const [searchedText, setSearchedText] = useState("");
  const { get } = useFetch();
  const { userId } = useQuery();
  useEffect(function () {
    refreshData();
  }, []);
  async function refreshData() {
    const resp = await get(`/DiscountVendor/list`);

    if (resp) {
      setData(resp as VendorType[]);
    }
  }
  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchedText(e.target.value);
  }

  const columns: ColumnsType<VendorType> = [
    {
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (String(record.Name)
          .toLowerCase()
          .includes(value.toString().toLowerCase()) ||
          String(record.Description)
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          String(record.Address)
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          String(record.CreatedDate)
            .toLowerCase()
            .includes(value.toString().toLowerCase())||
            String(record.UpdatedDate)
              .toLowerCase()
              .includes(value.toString().toLowerCase())
        )
      },
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      render: toLocalDateTime,
    },
    {
      title: "Updated Date",
      dataIndex: "UpdatedDate",
      key: "UpdatedDate",
      render: toLocalDateTime,
    },
    {
      title: "Action",
      dataIndex: "Id",
      key: "Id",
      render: (text, row) => <VendorActions row={row} />,
    },
  ];

  
  return <>
  <Card>
      <Row>
        <Col span={16}></Col>
        <Col span={8}>
          <Input.Search onChange={onSearchChange} placeholder="Search"></Input.Search>
        </Col>
      </Row>
    </Card>
  <AppDataTable<VendorType> rowKey="Id" data={data} columns={columns} />
  </>
}

type VendorType = {
  Id: number;
  Name: string;
  Description: string;
  Address: string;
  CreatedDate: string;
  UpdatedDate: string;
};

function VendorActions({ row }: { row: VendorType }) {
  const navigate = useNavigate();
  function editVendor() {
    navigate(`/vendors/form?vendorId=${row.Id}`);
  }
  return (
    <Space size="middle">
      <a onClick={editVendor}>Edit</a>
    </Space>
  );
}

