import { Input, Form } from "antd";
import { FieldsProps } from "./app-form";

export default function AppTextbox({
  children,
  label,
  name,
  type,
  rules,
  hidden,
  ...props
}: React.PropsWithChildren<TextboxProps>) {
  return (
    <Form.Item name={name} label={label} rules={rules}>
      <Input {...props} maxLength={100} />
    </Form.Item>
  );
}

export interface TextboxProps extends FieldsProps {
  placeholder: string;
}
