import React, { useEffect, useState } from "react";
import AppForm, {
  FieldTypes,
  FieldsProps,
} from "../../components/form/app-form";
import useFetch from "../../common/hooks/use-fetch";
import useQuery from "../../common/hooks/use-query";
import { TextboxProps } from "../../components/form/app-textbox";
import { useNavigate } from "react-router-dom";
import { type } from "os";
import { AppUploadProps } from "../../components/form/app-upload";
import { useUser } from "../../common/root-context";

export default function OrganizationForm() {
  const [org, setOrg] = useState<any>({});
  const user = useUser();
  const navigate = useNavigate();
  const { orgId } = useQuery();
  const { post, get } = useFetch();
  useEffect(function () {
    if (orgId) {
      (async function () {
        const temp = await get(`/organizations/${orgId}`);
        if (temp) {
          setOrg(temp);
        }
      })();
    } else if (user?.IsManager) {
      (async function () {
        const temp = await get(`/organizations/${user.OrganizationId}`);
        if (temp) {
          setOrg(temp);
        }
      })();
    }
  }, []);
  async function saveCard(data: any) {
    if (user?.IsManager) {
      await post("/organizations/edit-org/"+user.OrganizationId, { ...data, Id: user.OrganizationId });
      navigate("/");
    } else {
      await post("/organizations/save", { ...data, Id: orgId });
      navigate("/organizations");
    }
  }
  const FormFields: FieldsProps[] = [
    {
      name: "Logo",
      label: "Logo",
      type: FieldTypes.upload,
    } as AppUploadProps,
    {
      name: "CoverPhoto",
      label: "Cover Photo",
      hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin,
      type: FieldTypes.upload,
    } as AppUploadProps,
    {
      name: "DefaultProfilePhoto",
      label: "Default Profile Photo",
      type: FieldTypes.upload,
    } as AppUploadProps,
    {
      name: "SubDomain",
      label: "Sub Domain",
      placeholder: "Sub Domain / Link",
      hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin,
      type: FieldTypes.text,
      rules: [{ required: true, message: "Please input subdomain!" }],
    } as TextboxProps,
    {
      name: "Name",
      label: "Name",
      placeholder: "Microsoft inc.",
      type: FieldTypes.text,
      rules: [{ required: true, message: "Please input name!" }],
    } as TextboxProps,
    {
      name: "City",
      label: "City",
      placeholder: "Rawalpindi",
      type: FieldTypes.text,
      // rules: [{ required: true, message: "Please input City!" }],
    } as TextboxProps,
    {
      name: "Branch",
      label: "Branch",
      placeholder: "Main etc.",
      hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin,
      type: FieldTypes.text,
      // rules: [{ required: true, message: "Please input City!" }],
    } as TextboxProps,
    {
      name: "GoogleAnalyticsId",
      label: "Google Analytics ID",
      placeholder: "0000",
      hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin,
      type: FieldTypes.text,
      // rules: [{ required: true, message: "Please input City!" }],
    } as TextboxProps,
  ];

  return (
    <AppForm
      fields={FormFields}
      onSubmit={saveCard}
      key={org.Id}
      defaultData={org}
    />
  );
}


