import AppDataTable, {
  useTable,
} from "../../components/data-table/app-data-table";
import type { ColumnsType } from "antd/es/table";
import { Card, Col, Input, Row, Space, Tag } from "antd";
import { useUser } from "../../common/root-context";
import { ChangeEvent, useEffect, useState } from "react";
import useFetch from "../../common/hooks/use-fetch";
import { toLocalDateTime } from "../../common/utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function Organizations() {
  const user = useUser();
  const [data, setData] = useState<any>([]);
  const [searchedText, setSearchedText] = useState("");
  const { get } = useFetch();
  useEffect(function () {
    refreshData();
  }, []);

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchedText(e.target.value);
  }

  async function refreshData() {
    const temp = await get(`/organizations`);
    if (temp) setData(temp as OrgType);
  }

  const columns: ColumnsType<OrgType> = [
    {
      filteredValue: [searchedText],
        onFilter: (value, record) => {
          return (String(record.Name)
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
            String(record.City)
              .toLowerCase()
              .includes(value.toString().toLowerCase()) ||
            String(record.Branch)
              .toLowerCase()
              .includes(value.toString().toLowerCase()) ||
            String(record.UpdatedDate)
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        },
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "City",
      dataIndex: "City",
      key: "City",
    },
    {
      title: "Branch",
      dataIndex: "Branch",
      key: "Branch",
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      sorter: (a, b) => moment(a.CreatedDate).unix() - moment(b.CreatedDate).unix(),
      render: toLocalDateTime,
    },
    {
      title: "Action",
      dataIndex: "Id",
      key: "Id",
      render: (_, record) => <OrgActions row={record} />,
    },
  ];
  
  return <>
    <Card>
      <Row>
        <Col span={16}></Col>
        <Col span={8}>
          <Input.Search onChange={onSearchChange} placeholder="Search"></Input.Search>
        </Col>
      </Row>
    </Card>
    <AppDataTable<OrgType> rowKey="key" data={data} columns={columns} />
  </>
}

function OrgActions({ row }: { row: OrgType }) {
  const navigate = useNavigate();
  const table = useTable();
  const { get } = useFetch();
  function editOrg() {
    navigate(`/organization/form?orgId=${row.Id}`);
  }
  return (
    <Space size="middle">
      <a onClick={editOrg}>Edit</a>
    </Space>
  );
}

type OrgType = {
  Id: number;
  Name: string;
  City: string;
  Branch: string;
  CreatedDate: Date;
  UpdatedDate: Date;
};
