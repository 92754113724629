import React from "react";
import AppForm, {
  FieldTypes,
  FieldsProps,
  FormSectionType,
} from "../../components/form/app-form";
import useFetch from "../../common/hooks/use-fetch";
import { TextboxProps } from "../../components/form/app-textbox";

export default function UserForm() {
  const { post } = useFetch();
  async function saveUser(data: any) {
    await post("/user/create", data);
  }
  return <AppForm fields={FormFields} onSubmit={saveUser} />;
}

const FormFields: FieldsProps[] = [
  {
    name: "FirstName",
    label: "Full Name",
    placeholder: "Muhammad Ali",
    type: FieldTypes.text,
    rules: [{ required: true, message: "Please input user first name!" }],
  } as TextboxProps,
  // {
  //   name: "LastName",
  //   label: "Last Name",
  //   placeholder: "Ali",
  //   type: FieldTypes.text,
  //   rules: [{ required: true, message: "Please input user last name!" }],
  // } as TextboxProps,
];
