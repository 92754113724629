import { Button } from "antd";
import Auth from "../../components/Auth";
import {
  Document,
  PDFDownloadLink,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useConfirmation, useUser } from "../../common/root-context";
import { Key, useEffect, useState } from "react";
import useFetch, { BaseUrl } from "../../common/hooks/use-fetch";
import QRCode from "qrcode";
import {
  DeleteOutlined,
  QrcodeOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { UserType } from "./users-all";
import AppModal from "../../components/app-modal";
import useMasterData from "../../common/hooks/use-masterdata";
import AppSelect from "../../components/form/app-select";
import { PasswordProps } from "antd/es/input";

export default function UsersManagementAction({ refreshData, ...props }: any) {
  const selectedRows = props.selectedRows as UserType[];
  const user = useUser();
  const { post } = useFetch();
  const confirmationBox = useConfirmation();

  const [passwords, setPasswords] = useState([]);
  const [assignSupOpen, setAssignSupOpen] = useState(false);

  const getPasswords = async (selected: UserType[]) => {
    const userPasswords = await post(`/user/get-passwords`, {
      UserIds: selected.map((x) => x.Id),
    });
    setPasswords(userPasswords as any);
  };

  useEffect(
    function () {
      if (user?.IsSuperAdmin) getPasswords(selectedRows);
    },
    [selectedRows]
  );

  async function changeStatus(status: string) {
    confirmationBox(
      `Are you sure you want to update the status of ${selectedRows.length} User(s)`,
      async function () {
        const endPoint = user?.IsManager
          ? `change-status/${user.OrganizationId}`
          : "change-status";
        await post(`/user/${endPoint}`, {
          Status: parseInt(status),
          UserIds: selectedRows.map((x) => x.Id),
        });
        refreshData({});
      }
    );
  }

  function handleLockDetails(checked: boolean, locktype: string) {
    confirmationBox(
      `Are you sure you want to update the details of ${selectedRows.length} User(s)`,
      async function () {
        const endPoint = user?.IsManager
          ? `lock-details/${user.OrganizationId}`
          : "lock-details";
        await post(`/profile/${endPoint}`, {
          Type: locktype,
          Status: checked,
          UserIds: selectedRows.map((x) => x.Id),
        });
        refreshData({});
        // getLockDetails(selectedRows);
      }
    );
  }

  async function generateQRCodes() {
    selectedRows.forEach(async (row) => {
      let QRbase64 = await new Promise((resolve, reject) => {
        QRCode.toDataURL(
          `${BaseUrl}/${row.OrgSubDomain}/${row.Id}-${row.Slug}`,
          function (err: any, code: any) {
            if (err) {
              reject(reject);
              return;
            }
            resolve(code);
          }
        );
      });
      const linkSource = QRbase64;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource as string;
      downloadLink.download = `${row.Id}-${row.Slug}.png`;
      downloadLink.click();
    });
  }

  function assignSupervisor() {
    setAssignSupOpen(true);
  }

  return (
    <Auth roles={["admin", "superadmin", "manager"]}>
      <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
        <Auth roles={["admin", "superadmin"]}>
          <Button
            onClick={() => changeStatus("10")}
            disabled={!selectedRows.length}
          >
            Set Normal
          </Button>
          <Button
            onClick={() => changeStatus("11")}
            disabled={!selectedRows.length}
          >
            Lock
          </Button>
          <Button
            onClick={() => changeStatus("12")}
            disabled={!selectedRows.length}
          >
            Block
          </Button>
          <Button
            shape="circle"
            onClick={() => changeStatus("13")}
            disabled={!selectedRows.length}
            icon={<DeleteOutlined />}
          />
        </Auth>
        <Button
          onClick={() => handleLockDetails(true, "basicDetails")}
          disabled={!selectedRows.length}
        >
          Lock Basic Details
        </Button>
        <Button
          onClick={() => handleLockDetails(false, "basicDetails")}
          disabled={!selectedRows.length}
        >
          Unlock Basic Details
        </Button>
        <Button
          onClick={() => handleLockDetails(true, "socialAccounts")}
          disabled={!selectedRows.length}
        >
          Lock Social Accounts
        </Button>
        <Button
          onClick={() => handleLockDetails(false, "socialAccounts")}
          disabled={!selectedRows.length}
        >
          Unlock Social Accounts
        </Button>
        <Auth roles={["admin", "superadmin"]}>
          <Button
            onClick={() => handleLockDetails(true, "paymentDetails")}
            disabled={!selectedRows.length}
          >
            Lock Payment Details
          </Button>
          <Button
            onClick={() => handleLockDetails(false, "paymentDetails")}
            disabled={!selectedRows.length}
          >
            Unlock Payment Details
          </Button>
        </Auth>
        <Auth roles={["manager", "superadmin"]}>
          <Button onClick={assignSupervisor} disabled={!selectedRows.length}>
            Assign Supervisor
          </Button>
        </Auth>
        <Auth roles={["superadmin"]}>
          <Button
            onClick={generateQRCodes}
            disabled={!selectedRows.length}
            icon={<QrcodeOutlined />}
          >
            QR Code
          </Button>
          <PDFDownloadLink
            document={<PdfFile passwords={passwords} />}
            fileName="LoginDetails"
          >
            <Button disabled={!selectedRows.length} icon={<FilePdfOutlined />}>
              PDF
            </Button>
          </PDFDownloadLink>
        </Auth>
      </div>
      <SetSupervisorsModal
        isOpen={assignSupOpen}
        hideModal={() => setAssignSupOpen(false)}
        selectedRows={selectedRows}
      />
    </Auth>
  );
}

function SetSupervisorsModal({ isOpen, hideModal, ...props }: any) {
  const selectedRows = props.selectedRows as UserType[];
  const user = useUser();
  const [supervisor, setSupervisor] = useState();
  const { post } = useFetch();
  async function updateSupervisor() {
    if (selectedRows.length && supervisor) {
      await post(`/user/org/${user?.OrganizationId}/assign-supervisor`, {
        SupervisorId: supervisor,
        UserIds: selectedRows.map((x) => x.Id),
      });
      hideModal();
    }
  }
  const masterData = useMasterData("Supervisors");
  return (
    <AppModal
      isOpen={isOpen}
      title="Assign Supervisor"
      onCancel={hideModal}
      onOk={updateSupervisor}
    >
      {/* <AppForm 
        onSubmit={updateSupervisor}
        masterData={masterData}
        fields={[{
            label:"Supervisor",
            type:FieldTypes.select,
            
            getOptions:({Supervisors}:any)=>{return Supervisors.map((x: any) => ({
                label: x.Name,
                value: x.Id,
              }))}
        } as AppSelectProps]}>

        </AppForm> */}
      <AppSelect
        label="Supervisor"
        options={masterData?.Supervisors.map((x: any) => ({
          label: x.FirstName,
          value: x.Id,
        }))}
        onChange={(x) => setSupervisor(x)}
      />
    </AppModal>
  );
}

const PdfFile = ({ passwords }: any) => (
  <Document>
    <Page size="A4" style={pdfStyles.body}>
      {passwords.map((element: any, index: number) => (
        <View >
          <Text style={pdfStyles.title}>
            Name: {" "} {element.FirstName}
          </Text>
          <Text style={pdfStyles.heading}>
            How to Activate?
          </Text>
          <Text style={pdfStyles.text}>
            1)  Open Website: digitalvisitingcard.app
          </Text>
          <Text style={pdfStyles.text}>
            2)  Enter Card Id:
            {element.NewPassword?.UserId}{" "}
            & Password: {element.NewPassword?.Password}{" "}
          </Text>
          <Text style={pdfStyles.text}>
            3)  Now enter valid Email & verify OTP.
          </Text>
          <Text style={pdfStyles.text}>
            4)  Set a new password.
          </Text>
          <Text style={pdfStyles.text}>
            5)  You're all set to add your details in your card.
          </Text>
        </View>
      ))}
    </Page>
  </Document>
);

const pdfStyles = StyleSheet.create({
  body: {
    padding: 15,
  },
  title: {
    fontSize: 12,
    marginTop: 20,
    fontFamily: "Times-Bold",
  },
  text: {
    fontSize: 8,
    textAlign: "left",
    marginBottom: 5,
  },
  heading: {
    fontSize: 12,
    marginBottom: 10,
    marginTop: 10,
    textAlign: "left",
    fontFamily: "Times-Bold",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
});

{
  /* <label>Basic Details:</label>
        <Switch
          checked={lockDetails.basicDetails}
          title="Lock Basic Details"
          onClick={(checked) => handleLockDetails(checked, "basicDetails")}
          disabled={!selectedRows.length}
        />
        <label>Social Accounts:</label>
        <Switch
          checked={lockDetails.socialAccounts}
          title="Lock Social Accounts"
          onClick={(checked) => handleLockDetails(checked, "socialAccounts")}
          disabled={!selectedRows.length}
        />
        <Auth roles={["admin", "superadmin"]}>
          <label>Payment Details:</label>
          <Switch
            checked={lockDetails.paymentDetails}
            title="Lock Payment Details"
            onClick={(checked) => handleLockDetails(checked, "paymentDetails")}
            disabled={!selectedRows.length}
          />
        </Auth> */
}
