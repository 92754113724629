import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import useFetch, { USER_KEY, useSuccess } from "../../common/hooks/use-fetch";
import useQuery from "../../common/hooks/use-query";
import { useLogin } from "../auth/login-form";
import { validatePasswordRules } from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../common/root-context";

const ChangePasswordForm: React.FC = () => {
  const { post } = useFetch();
  const user = useUser();
  const navigate = useNavigate();
  const success = useSuccess();

  const onFinish = async (values: any) => {
    await post("/user/change-password", {
      ...values,
      UserId: user?.Id,
    });
    success("Password Changed Successfully.")
    setTimeout(()=> navigate(-1), 500);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      layout="vertical"
      name="basic"
      className="auth-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      style={{margin:"auto"}}
    >
      <Form.Item
        label="Current Password"
        name="CurrentPassword"
        rules={[{ required: true, message: "Please input your current Password!" }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Set New Password"
        name="NewPassword"
        rules={[
          { required: true, message: "Please input your new Password!" },
          () => ({
            validator(_, value) {
              return validatePasswordRules(value);
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Repeat Password"
        name="Password2"
        rules={[
          { required: true, message: "Please input your Repeat Password." },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && getFieldValue("NewPassword") !== value) {
                return Promise.reject(
                  new Error("The two passwords that you entered do not match.")
                );
              }
              return validatePasswordRules(value);
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item style={{textAlign:"end"}}>
        <Button type="primary" htmlType="submit" className="btn-main" style={{maxWidth: 200, marginTop:"20px"}}>
          Update Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordForm;