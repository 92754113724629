import { useEffect, useState } from "react";
import { useConfirmation, useUser } from "../../common/root-context";
import AppDataTable, {
  useTable,
} from "../../components/data-table/app-data-table";
import useFetch from "../../common/hooks/use-fetch";
import { ColumnsType } from "antd/es/table";
import useQuery from "../../common/hooks/use-query";
import { toLocalDateTime } from "../../common/utils";
import { useNavigate } from "react-router-dom";
import { Space, Tag } from "antd";

export default function Deals() {
  const user = useUser();
  const [data, setData] = useState<DealType[]>([]);
  const { get } = useFetch();
  useEffect(function () {
    refreshData();
  }, []);
  async function refreshData() {
    const resp = await get(`/DiscountDeal/${user?.VendorId}/list`);

    if (resp) {
      setData(resp as DealType[]);
    }
  }
  return (
    <AppDataTable<DealType>
      rowKey="Id"
      data={data}
      columns={columns}
      refreshData={refreshData}
    />
  );
}

type DealType = {
  Id: number;
  Name: string;
  Description: string;
  ActualPrice: string;
  DiscountPrice: string;
  CycleDays: string;
  CycleLimit: string;
  IsActive: string;
  ExpiryDate: string;
  CreatedDate: string;
  UpdatedDate: string;
};

function DealActions({ row }: { row: DealType }) {
  const confBox = useConfirmation();
  const table = useTable();
  const user = useUser();
  const { get } = useFetch();
  const navigate = useNavigate();
  function edit() {
    navigate(`/deals/form?dealId=${row.Id}`);
  }
  function updateStatus() {
    confBox(
      `Are you sure you want to ${
        !row.IsActive ? "Activate" : "Deactivate"
      } the deal: '${row.Name}'?`,
      async function () {
        await get(`/DiscountDeal/${user?.VendorId}/change-status/${row.Id}`, {
          query: { activate: !row.IsActive },
        });
        table?.refreshData && table?.refreshData();
      }
    );
  }
  return (
    <Space size="middle">
      <a onClick={edit}>Edit</a>
      <a onClick={updateStatus}>{row.IsActive ? "Deactivate" : "Activate"}</a>
    </Space>
  );
}
const columns: ColumnsType<DealType> = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
  },
  {
    title: "Description",
    dataIndex: "Description",
    key: "Description",
  },
  {
    title: "Actual Price",
    dataIndex: "ActualPrice",
    key: "ActualPrice",
  },
  {
    title: "Discount Price",
    dataIndex: "DiscountPrice",
    key: "DiscountPrice",
  },
  {
    title: "Cycle Days",
    dataIndex: "CycleDays",
    key: "CycleDays",
  },
  {
    title: "Cycle Limit",
    dataIndex: "CycleLimit",
    key: "CycleLimit",
  },
  {
    title: "Is Active",
    dataIndex: "IsActive",
    key: "IsActive",
    render: (val) => (
      <Tag color={val ? "green" : "volcano"}>{val ? "YES" : "NO"}</Tag>
    ),
  },
  {
    title: "Expiry Date",
    dataIndex: "ExpiryDate",
    key: "ExpiryDate",
    render: toLocalDateTime,
  },
  {
    title: "Created Date",
    dataIndex: "CreatedDate",
    key: "CreatedDate",
    render: toLocalDateTime,
  },
  {
    title: "Action",
    dataIndex: "Id",
    key: "Id",
    render: (text, row) => <DealActions row={row} />,
  },
];
