import type { ColumnsType } from "antd/es/table";
import AppDataTable, {
  useTable,
} from "../../components/data-table/app-data-table";
import {
  CopyOutlined,
  MenuOutlined,
  EditOutlined,
  SettingOutlined,
  FileExcelOutlined,
  DeleteOutlined,
  QrcodeOutlined,
  FilePdfOutlined,
  CheckCircleOutlined,
  StopOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import React, {
  ChangeEvent,
  EventHandler,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import useFetch, { ApiBaseUrl, BaseUrl } from "../../common/hooks/use-fetch";
import { toLocalDateTime } from "../../common/utils";
import {
  Input,
  Button,
  Card,
  Col,
  Dropdown,
  MenuProps,
  Row,
  Space,
  Tag,
  Tooltip,
  Switch,
  Collapse,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useConfirmation, useRoot, useUser } from "../../common/root-context";
import AppForm, {
  FieldTypes,
  FieldsProps,
} from "../../components/form/app-form";
import useMasterData, {
  useMasterData2,
} from "../../common/hooks/use-masterdata";
import AppSelect, { AppSelectProps } from "../../components/form/app-select";
import { type } from "os";
import moment from "moment";
import ExcelJS from "exceljs";
import AppSwitch from "../../components/form/app-switch";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import Auth from "../../components/Auth";
import AppModal from "../../components/app-modal";
import UsersManagementAction from "./users-all-action";

const statusOptions = [
  {
    value: 10,
    label: "Normal",
  },
  {
    value: 11,
    label: "Locked",
  },
  {
    value: 12,
    label: "Blocked",
  },
];

const EmailOptions = [
  {
    value: 1,
    label: "Verified Only",
  },
  {
    value: 2,
    label: "Unverified Only",
  },
];

export default function UsersAll() {
  const user = useUser();
  const [masterData, refreshMasterData] = useMasterData2(
    user?.IsManager ? "Supervisors" : "Organizations"
  );
  const [selectedRows, setSelectedRows] = useState<UserType[]>([]);
  const [searchedText, setSearchedText] = useState("");
  const [data, setData] = useState<UserType[]>([]);
  const { get, post } = useFetch();

  useEffect(function () {
    refreshData({});
  }, []);

  async function refreshData(filter?: any) {
    const usersEndPoint =
      user?.IsSuperAdmin || user?.IsAdmin
        ? "all"
        : user?.IsManager
        ? "by-company"
        : user?.IsSupervisor
        ? "by-team"
        : "me";
    const temp = await get(`/user/${usersEndPoint}`, { query: filter });
    if (temp) setData(temp as UserType[]);
  }

  function searchUsers(data: any) {
    refreshData({
      ...data,
      dateFrom: data?.dateFrom?.startOf("day").toISOString() || undefined,
      dateTo: data?.dateTo?.endOf("day").toISOString() || undefined,
      expiryDateFrom:
        data?.expiryDateFrom?.endOf("day").toISOString() || undefined,
      expiryDateTo: data?.expiryDateTo?.endOf("day").toISOString() || undefined,
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: UserType[]) => {
      setSelectedRows(selectedRows);
    },
  };

  function handelFilterChange(data: any) {
    if (data)
      if (data.hasOwnProperty("orgId")) {
        refreshMasterData("Supervisors", data);
      }
  }

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchedText(e.target.value);
  };

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Users");
    sheet.columns = [
      {
        header: "Name",
        key: "name",
      },
      {
        header: "Link",
        key: "link",
      },
      {
        header: "Email",
        key: "email",
      },
      {
        header: "Created Date",
        key: "createdDate",
      },
    ];
    data.map((obj) => {
      sheet.addRow({
        name: obj.FirstName,
        link: `${BaseUrl}/${obj.OrgSubDomain}/${obj.Id}-${obj.Slug}`,
        email: obj.Email,
        createdDate: obj.CreatedDate,
      });
    });

    workbook.csv.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "text/csv;charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Users " + new Date().toDateString() + ".csv";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const columns: ColumnsType<UserType> = [
    {
      title: "Name",
      dataIndex: "FirstName",
      key: "FirstName",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.FirstName)
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          String(record.Id)
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          String(record.Email)
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          String(record.CreatedDate)
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        );
      },
      render: (text, { FirstName, LastName }) => (
        <>
          {FirstName} {LastName}
        </>
      ),
    },
    {
      title: "Link",
      dataIndex: "Id",
      key: "Id",
      render: (text, { Slug, Id, OrgSubDomain }) => (
        <>
          <a
            href={`/${OrgSubDomain}/${Id}-${Slug}`}
            target="_blank"
          >{`/${OrgSubDomain}/${Id}-${Slug}`}</a>

          <Tooltip title="Copy">
            <CopyOutlined
              onClick={() => {
                navigator.clipboard.writeText(`/${OrgSubDomain}/${Id}-${Slug}`);
              }}
            />
          </Tooltip>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      responsive: ["xl"],
    },
    {
      title: "Status",
      key: "Status",
      dataIndex: "Status",
      sorter: (a, b) => a.Status - b.Status,
      render: (Status: number) => (
        <Tag color={{ 10: "success", 11: "warning", 12: "error" }[Status]}>
          {{ 10: "normal", 11: "locked", 12: "blocked" }[Status]?.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Created Date",
      key: "CreatedDate",
      dataIndex: "CreatedDate",
      responsive: ["xl"],
      render: toLocalDateTime,
      sorter: (a, b) =>
        moment(a.CreatedDate).unix() - moment(b.CreatedDate).unix(),
    },
    {
      title: "Expiry Date",
      key: "ExpiryDate",
      dataIndex: "ExpiryDate",
      responsive: ["xl"],
      render: toLocalDateTime,
      sorter: (a, b) =>
        moment(a.CreatedDate).unix() - moment(b.CreatedDate).unix(),
    },
    {
      title: "Action",
      dataIndex: "Id",
      key: "Id",
      render: (_, record) => <UserActions row={record} />,
    },
  ];

  const [filterFormKey, setFilterFormKey] = useState<number>();

  return (
    <Row>
      <Col span={24}>
        <Collapse style={{ width: "100%" }}>
          <Collapse.Panel
            key="filterPanel"
            header="Filters"
            style={{ width: "100%" }}
          >
            <AppForm
              masterData={{
                ...masterData,
                Statuses: statusOptions,
                EmailOptions,
              }}
              fields={filterFields}
              onValuesChange={handelFilterChange}
              onSubmit={searchUsers}
              submitText="Search"
              key={filterFormKey}
              reset={() => {
                setFilterFormKey(Date.now());
              }}
            />
          </Collapse.Panel>
        </Collapse>
      </Col>
      <Col span={24}>
        <Card>
          <Row gutter={[20, 20]}>
            <Col span={24}>
              <UsersManagementAction {...{ selectedRows, refreshData }} />
            </Col>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "10px",
              }}
            >
              <Input.Search
                style={{ maxWidth: "250px" }}
                onChange={onSearchChange}
                placeholder="Search"
              ></Input.Search>
              <Tooltip title="Export to CSV">
                <Button
                  style={{
                    marginLeft: "10px",
                    color: "White",
                    background: "#075400",
                  }}
                  onClick={exportExcelFile}
                  type="primary"
                  shape="circle"
                  icon={<FileExcelOutlined />}
                ></Button>
              </Tooltip>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <AppDataTable<UserType>
          rowSelection={rowSelection}
          refreshData={refreshData}
          rowKey="Id"
          data={data}
          columns={columns}
        />
      </Col>
    </Row>
  );
}

function UserActions({ row }: { row: UserType }) {
  const navigate = useNavigate();
  const table = useTable();
  const { get } = useFetch();

  // async function deactivateUser() {
  //   await get(`/user/change-status?userId=${row.Id}`);
  //   table?.refreshData && table?.refreshData();
  // }
  // async function changeStatus(key: string) {
  //   await get(`/user/change-status?userId=${row.Id}&status=${key}`);
  //   table?.refreshData && table?.refreshData();
  // }
  function handelClick({ key, ...e }: any) {
    if (key === "edit") navigate(`/user-profile?userId=${row.Id}`,{state: row});
    else if (key === "settings") navigate(`/user/settings?userId=${row.Id}`);
    //else if (["10", "11", "12"].indexOf(key) > -1) changeStatus(key);
  }

  return (
    <Space size="middle">
      <Dropdown
        menu={{
          items: items?.map((x) => ({
            ...x,
            disabled: x?.key === row.Status,
          })) as MenuProps["items"],
          onClick: handelClick,
        }}
        trigger={["click"]}
      >
        <MenuOutlined />
      </Dropdown>
      {/* <a onClick={openSettings}>Settings</a>
      <a onClick={editUser}>Edit</a>
      {row.IsActive ? (
        <a onClick={deactivateUser}>Deactivate</a>
      ) : (
        <a onClick={activateUser}>Activate</a>
      )} */}
    </Space>
  );
}

interface UserPassword {
  Id: number;
  UserId: number;
  Password: string;
}

export interface UserType {
  ContactNumber: string;
  CreatedDate: string;
  Email: string;
  ExpiryDate: string;
  FirstName: string;
  Status: number;
  LastName: string;
  Organization: any;
  OrganizationId: number;
  Reference: string;
  RenewalDate: string;
  UpdatedDate: string;
  Id: number;
  Slug: string;
  OrgSubDomain: string;
}

const items: MenuProps["items"] = [
  {
    label: "Edit Profile",
    key: "edit",
    icon: <EditOutlined />,
  },
  {
    label: "Edit User",
    key: "settings",
    icon: <SettingOutlined />,
  },
  // {
  //   type: "divider",
  // },
  // {
  //   label: "Normal",
  //   key: "10",
  //   icon: <CheckCircleOutlined />,
  // },
  // {
  //   label: "Locked",
  //   key: "11",
  //   icon: <CloseCircleOutlined />,
  // },
  // {
  //   label: "Blocked",
  //   key: "12",
  //   icon: <StopOutlined />,
  // },
];

const filterFields: FieldsProps[] = [
  {
    name: "orgId",
    label: "Organization",
    type: FieldTypes.select,
    hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin,
    getOptions: ({ Organizations }: any) =>
      Organizations &&
      Organizations.map((x: any) => ({ label: x.Name, value: x.Id })),
  } as AppSelectProps,
  {
    name: "supervisorId",
    label: "Supervisor",
    type: FieldTypes.select,
    hidden: (_, { user }) =>
      !user?.IsSuperAdmin && !user?.IsAdmin && !user?.IsManager,
    getOptions: ({ Supervisors }: any) =>
      Supervisors &&
      Supervisors.map((x: any) => ({
        label: x.FirstName,
        value: x.Id,
      })),
  } as AppSelectProps,
  {
    name: "status",
    label: "Status",
    type: FieldTypes.select,
    getOptions: ({ Statuses }: any) =>
      Statuses &&
      Statuses.map((x: any) => ({
        label: x.label,
        value: x.value,
      })),
  } as AppSelectProps,
  {
    name: "emailVerified",
    label: "Email Status",
    type: FieldTypes.select,
    getOptions: ({ EmailOptions }: any) =>
      EmailOptions &&
      EmailOptions.map((x: any) => ({
        label: x.label,
        value: x.value,
      })),
  } as AppSelectProps,
  {
    name: "dateFrom",
    label: "Created Date From",
    type: FieldTypes.date,
  },
  {
    name: "dateTo",
    label: "Created Date To",
    type: FieldTypes.date,
  },
  {
    name: "expiryDateFrom",
    label: "Expiry Date From",
    type: FieldTypes.date,
  },
  {
    name: "expiryDateTo",
    label: "Expiry Date To",
    type: FieldTypes.date,
  },
];
