import { useEffect, useState } from "react";
import AppForm, {
  FieldTypes,
  FieldsProps,
} from "../../../components/form/app-form";
import useFetch from "../../../common/hooks/use-fetch";
import useQuery from "../../../common/hooks/use-query";
import { useConfirmation, useUser } from "../../../common/root-context";
import useMasterData from "../../../common/hooks/use-masterdata";
import { AppSelectProps } from "../../../components/form/app-select";
import { useNavigate } from "react-router-dom";
import { Button, Col, Input, Row } from "antd";
import Papa from "papaparse";
import Auth from "../../../components/Auth";
import { useForm } from "antd/es/form/Form";

export default function UserSettings() {
  const [userData, setUser] = useState<any>({ PaymentMethodsLocked: true });
  const navigate = useNavigate();
  const masterData = useMasterData("Organizations,Supervisors,Vendors,CardPlans");
  const { userId } = useQuery();
  const confirmationBox = useConfirmation();
  const [data, setData] = useState([]);
  const user = useUser();
  const { post, get } = useFetch();
  const [form] = useForm();
  useEffect(
    function () {
      if (userId) {
        (async function () {
          const usersEndPoint = user?.IsManager
            ? `/${user.OrganizationId}`
            : "";
          const temp = (await get(
            `/user${usersEndPoint}?userId=${userId}`
          )) as any;
          if (temp) {
            //temp.Roles = temp.User.Roles.map((x: any) => x.Role).join(",");
            setUser(temp);
          }
        })();
        return;
      }
      setUser({});
    },
    [userId]
  );
  useEffect(() => {
    if (!userId) {
      const selectedOrg = masterData?.Organizations.find((item: { Name: string; }) => item.Name.includes("Digital Business Card"))?.Id;
      const selectedPlan = masterData?.CardPlans.find((item: { Name: string; }) => item.Name.includes("Standard"))?.Id;
      setUser({ ...userData, CardPlanId: selectedPlan, OrganizationId: selectedOrg })
    }
  }, [masterData]);

  useEffect(() => {
    form.resetFields();
  }, [userData]);

  async function saveUser(data: any) {
    const endPoint = user?.IsSuperAdmin
      ? "/user/"
      : `/user/${user?.OrganizationId}`;
    await post(endPoint, { ...data, UserId: userId });
    navigate("/users");
  }

  function handleGenerateUSers() {
    if (data.length > 1) {
      confirmationBox(
        `Are you sure you want to create ${data.length} new Users`,
        async function () {
          const endPoint = "/user/generate-users";
          await post(endPoint, data);
          navigate("/users");
        }
      );
    }
  }

  function handleFile(e: any): void {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const tempData: { FullName: any; CardPlan: any; Organization: any }[] =
        [];
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const temp = result.data;
          temp.forEach((obj: any) => {
            if (
              obj.hasOwnProperty("Name") &&
              obj.hasOwnProperty("Organization") &&
              obj.hasOwnProperty("Plan")
            ) {
              if (obj.Name != "" && obj.Organization != "") {
                const newObj = {
                  FullName: obj.Name,
                  Organization: obj.Organization,
                  CardPlan: obj.Plan
                };
                tempData.push(newObj);
              }
            }
          });
        },
      });
      setData(tempData as any);
    }
  }
  /* const columns = [
    {
      title: "First Name",
      dataIndex: "FirstName",
      key: "name",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      key: "name",
    },
    {
      title: "Organization",
      dataIndex:"Organization",
      key: "organization",
    }
  ]; */
  const paymentStatus = [
    {
      value: "Un-Paid",
    },
    {
      value: "Paid",
    },
  ];

  return (
    <>
      <AppForm
        form={form}
        sections={tempSec}
        onSubmit={saveUser}
        key={userData.UserId}
        defaultData={userData}
        masterData={{ ...masterData, paymentStatus: paymentStatus }}
      />
      <Auth roles={["superadmin"]}>
        <Row>
          <Col span={10}>
            <Input type="file" onChange={handleFile} accept=".csv" />
          </Col>
          <Col span={2} style={{ marginLeft: "20px" }}>
            <Button onClick={handleGenerateUSers}>Generate Users</Button>
          </Col>
        </Row>
      </Auth>
      {/* <Row >
      <Col span={24}>
        <AppDataTable data={data} columns={columns} rowKey={"Id"}></AppDataTable>
      </Col>
    </Row> */}
    </>
  );
}

const GeneralDetails: FieldsProps[] = [
  {
    name: "FirstName",
    label: "Full Name",
    type: FieldTypes.text,
    rules: [{ required: true, message: "Please input first name!" }],
  },
  // {
  //   name: "LastName",
  //   label: "Last Name",
  //   type: FieldTypes.text,
  //   rules: [{ required: true, message: "Please input last name!" }],
  // },
  {
    name: "OrganizationId",
    label: "Organization",
    // placeholder: "Microsoft Inc.",
    hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin,
    type: FieldTypes.select,
    getOptions: ({ Organizations }: any) =>
      Organizations &&
      Organizations.map((x: any) => ({ label: x.Name, value: x.Id })),
    rules: [{ required: true, message: "Please select organization!" }],
  } as AppSelectProps,
  {
    name: "CardPlanId",
    label: "Card Plan",
    hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin,
    type: FieldTypes.select,
    getOptions: ({ CardPlans }: any) =>
      CardPlans &&
      CardPlans.map((x: any) => ({ label: x.Name, value: x.Id })),
    rules: [{ required: true, message: "Please select Card Plan!" }],
  } as AppSelectProps,
  {
    name: "VendorId",
    label: "Vendor",
    // placeholder: "Microsoft Inc.",
    hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin,
    type: FieldTypes.select,
    getOptions: ({ Vendors }: any) =>
      Vendors &&
      Vendors.map((x: any) => ({
        label: x.Name + ` (${x.Address})`,
        value: x.Id,
      })),
  } as AppSelectProps,
  {
    name: "SupervisorId",
    label: "Supervisor",
    hidden: (_, { user }) => !user?.IsManager,
    type: FieldTypes.select,
    getOptions: ({ Supervisors }: any) =>
      Supervisors &&
      Supervisors.map((x: any) => ({
        label: x.FirstName,
        value: x.Id,
      })),
    // placeholder: "Microsoft Inc.",
    // rules: [{ required: true, message: "Please input name!" }],
  } as AppSelectProps,
  {
    name: "IsVendorUser",
    label: "Is Vendor User",
    type: FieldTypes.switch,
    hidden: (_, { user }) => !user?.IsSuperAdmin,
  },
  {
    name: "IsManager",
    label: "Is Manager",
    type: FieldTypes.switch,
    hidden: (_, { user }) => !user?.IsSuperAdmin,
  },
  {
    name: "IsSupervisor",
    label: "Is Supervisor",
    type: FieldTypes.switch,
    hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsManager,
  },
  {
    name: "Locked",
    label: "Basic Details Locked",
    type: FieldTypes.switch,
  },
  // {
  //   name: "ContactsLocked",
  //   label: "Contacts Locked",
  //   type: FieldTypes.switch,
  // },
  // {
  //   name: "EmailAddressesLocked",
  //   label: "Email Addresses Locked",
  //   type: FieldTypes.switch,
  // },
  // {
  //   name: "AddressesLocked",
  //   label: "Addresses Locked",
  //   type: FieldTypes.switch,
  // },
  {
    name: "LinksLocked",
    label: "Social Links Locked",
    type: FieldTypes.switch,
  },
  {
    name: "PaymentMethodsLocked",
    label: "Payment Details Locked",
    type: FieldTypes.switch,
  },
];

/* const PaymentDetails: FieldsProps[] = [
  {
    label: "Partial Payment Amount",
    name: "PartialPaymentAmount",
    type: FieldTypes.text,
  },
  {
    label: "Partial Payment Status",
    name: "PartialPaymentStatus",
    type: FieldTypes.select,
    getOptions: ({ paymentStatus }: any) =>
      paymentStatus &&
      paymentStatus.map((x: any) => ({
        label: x.value,
        value: x.value,
      })),
  }as AppSelectProps,
  {
    label: "Partial Payment Date",
    name: "PartialPaymentDate",
    type: FieldTypes.date,
  },
  {
    label: "Partial Payment Source",
    name: "PartialPaymentSource",
    type: FieldTypes.text,
  },
  {
    label: "Remaining Payment Amount",
    name: "RemainingPaymentAmount",
    type: FieldTypes.text,
  },
  {
    label: "Remaining Payment Status",
    name: "RemainingPaymentStatus",
    type: FieldTypes.select,
    getOptions: ({ paymentStatus }: any) =>
      paymentStatus &&
      paymentStatus.map((x: any) => ({
        label: x.value,
        value: x.value,
      })),
  }as AppSelectProps,
  {
    label: "Remaining Payment Source",
    name: "RemainigPaymentSource",
    type: FieldTypes.text,
  },
];

const CourierDetails: FieldsProps[] = [
  {
    label: "Courier Company Name",
    name: "CourierCompanyName",
    type: FieldTypes.text,
  },
  {
    label: "Delivery Type",
    name: "DeliveryType",
    type: FieldTypes.select,
  },
  {
    label: "Courier Amount",
    name: "CourierAmount",
    type: FieldTypes.text,
  },
  {
    label: "Courier Address",
    name: "CourierAddress",
    type: FieldTypes.text,
  },
  {
    label: "Courier Date",
    name: "CourierDate",
    type: FieldTypes.date,
  },
  {
    label: "Courier Reaching Date",
    name: "CourierReachingDate",
    type: FieldTypes.date,
  },
  {
    label: "Courier Order Id",
    name: "CourierOrderId",
    type: FieldTypes.text,
  },
  {
    label: "Courier Return Reason",
    name: "CourierReturnReason",
    type: FieldTypes.text,
  },
  {
    label: "Order Status",
    name: "OrderStatus",
    type: FieldTypes.select,
  },
  {
    label: "Courier Status",
    name: "CourierStatus",
    type: FieldTypes.text,
  },
]; */

const tempSec = [
  {
    fields: GeneralDetails,
    title: "User Details",
  },
  /*  {
    fields: PaymentDetails,
    title: "Payment Details",
  },
  {
    fields: CourierDetails,
    title: "Courier Details",
  } */
];
