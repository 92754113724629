import React, { createContext, useContext } from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";

var TableContext = createContext<TableContextType | null>(null);
export const useTable = () => useContext(TableContext);

export default function AppDataTable<T extends object>({
  refreshData,
  ...props
}: React.PropsWithChildren<TableProps<T>>) {
  return (
    <TableContext.Provider value={{ refreshData }}>
      <Table
        rowSelection={props.rowSelection}
        pagination={{pageSize:20}}
        rowKey={props.rowKey}
        columns={props.columns}
        dataSource={props.data}
      />
    </TableContext.Provider>
  );
}

export type TableContextType = {
  refreshData?: (filter?: any) => void;
};
export type TableProps<T> = {
  rowSelection?: TableRowSelection<T> | undefined;
  refreshData?: () => void;
  rowKey: string;
  data: T[];
  columns: ColumnsType<T>;
};
