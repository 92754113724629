import React, { useEffect, useState } from "react";
import AppForm, {
  FieldTypes,
  FieldsProps,
} from "../../components/form/app-form";
import useFetch from "../../common/hooks/use-fetch";
import useQuery from "../../common/hooks/use-query";
import { TextboxProps } from "../../components/form/app-textbox";
import { useNavigate } from "react-router-dom";

export default function VendorForm() {
  const [vendor, setVendor] = useState<any>({});
  const navigate = useNavigate();
  const { vendorId } = useQuery();
  const { post, get } = useFetch();
  useEffect(function () {
    if (vendorId) {
      (async function () {
        const temp = await get(`/DiscountVendor/${vendorId}`);
        if (temp) {
          setVendor(temp);
        }
      })();
    }
  }, []);
  async function save(data: any) {
    await post("/DiscountVendor", { ...data, Id: vendorId });
    navigate("/vendors/all");
  }
  return (
    <AppForm
      fields={FormFields}
      onSubmit={save}
      key={vendor.Id}
      defaultData={vendor}
    />
  );
}

const FormFields: FieldsProps[] = [
  {
    name: "Name",
    label: "Name",
    placeholder: "KFC inc.",
    type: FieldTypes.text,
    rules: [{ required: true, message: "Please input name!" }],
  } as TextboxProps,
  {
    name: "Description",
    label: "Description",
    type: FieldTypes.text,
  } as TextboxProps,
  {
    name: "Address",
    label: "Address",
    type: FieldTypes.text,
  } as TextboxProps,
];
