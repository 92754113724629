import React, { useEffect, useState } from "react";
import AppForm, {
  FieldTypes,
  FieldsProps,
} from "../../components/form/app-form";
import useFetch from "../../common/hooks/use-fetch";
import useQuery from "../../common/hooks/use-query";
import { TextboxProps } from "../../components/form/app-textbox";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../common/root-context";

export default function DealForm() {
  const user = useUser();
  const [deal, setDeal] = useState<any>({});
  const navigate = useNavigate();
  const { dealId } = useQuery();
  const { post, get } = useFetch();
  useEffect(function () {
    if (dealId) {
      (async function () {
        const temp = await get(`/DiscountDeal/${user?.VendorId}/${dealId}`);
        if (temp) {
          setDeal(temp);
        }
      })();
    }
  }, []);
  async function save(data: any) {
    await post(`/DiscountDeal/${user?.VendorId}`, { ...data, Id: dealId });
    navigate("/deals/all");
  }
  return (
    <AppForm
      fields={FormFields}
      onSubmit={save}
      key={deal.Id}
      defaultData={deal}
    />
  );
}

const FormFields: FieldsProps[] = [
  {
    name: "Photo",
    label: "Photo",
    type: FieldTypes.upload,
  },
  {
    name: "Name",
    label: "Name",
    type: FieldTypes.text,
    rules: [{ required: true, message: "Please input name!" }],
  } as TextboxProps,
  {
    name: "Description",
    label: "Description",
    type: FieldTypes.text,
  } as TextboxProps,
  {
    name: "ActualPrice",
    label: "Actual Price",
    type: FieldTypes.text,
  } as TextboxProps,
  {
    name: "DiscountPrice",
    label: "Discount Price",
    type: FieldTypes.text,
  } as TextboxProps,
  {
    name: "CycleDays",
    label: "Cycle Days",
    type: FieldTypes.text,
  } as TextboxProps,
  {
    name: "CycleLimit",
    label: "Cycle Limit",
    type: FieldTypes.text,
  } as TextboxProps,
];
