import { Button, Modal } from "antd";
import { PropsWithChildren, useState } from "react";

export default function AppModal(props: PropsWithChildren<ModalPropsType>) {
  return (
    <Modal {...props} open={props.isOpen}>
      {props.children}
    </Modal>
  );
}

interface ModalPropsType {
  title: string;
  onOk: () => void;
  onCancel: () => void;
  isOpen: boolean;
}
