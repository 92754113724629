import { useEffect, useState } from "react";
import useFetch from "./use-fetch";
import { useUser } from "../root-context";

export default function useMasterData(types: string, params?: any, deps?: any) {
  const [masterData, setMasterData] = useState<any>();
  const user = useUser();
  const { get } = useFetch();
  useEffect(
    function () {
      if (types) {
        const endp =
          user?.IsSuperAdmin || user?.IsAdmin
            ? "by-types"
            : user?.IsManager
            ? `by-types/${user?.OrganizationId}`
            : user?.IsSupervisor
            ? `by-types-team/${user?.OrganizationId}/${user?.Id}`
            : "";
        endp &&
          (async function () {
            const temp = await get(`/masterdata/${endp}?types=${types}`, {
              query: params,
            });
            setMasterData(temp);
          })();
      }
    },
    [deps]
  );
  return masterData;
}

export function useMasterData2(types: string) {
  const [masterData, setMasterData] = useState<any>();
  const user = useUser();
  const { get } = useFetch();
  useEffect(function () {
    if (types) {
      refreshData(types);
    }
  }, []);

  async function refreshData(types: string, params?: any) {
    const endp =
      user?.IsSuperAdmin || user?.IsAdmin
        ? "by-types"
        : user?.IsManager
        ? `by-types/${user?.OrganizationId}`
        : user?.IsSupervisor
        ? `by-types-team/${user?.OrganizationId}/${user?.Id}`
        : "";
    if (!endp) return;
    const temp = await get(`/masterdata/${endp}`, {
      query: { ...params, types: types },
    });
    setMasterData({ ...masterData, ...(temp as any) });
  }
  return [masterData, refreshData];
}
