//three views:
//my leads (for simple user)
//user leads
//filter leads

import { ChangeEvent, useEffect, useState } from "react";
import { useUser } from "../../common/root-context";
import AppDataTable from "../../components/data-table/app-data-table";
import useFetch from "../../common/hooks/use-fetch";
import { ColumnsType } from "antd/es/table";
import useQuery from "../../common/hooks/use-query";
import { toLocalDateTime } from "../../common/utils";
import { Button, Card, Col, Input, Row, Space, Tooltip } from "antd";
import AppForm, {
  FieldTypes,
  FieldsProps,
} from "../../components/form/app-form";
import { AppSelectProps } from "../../components/form/app-select";
import { useMasterData2 } from "../../common/hooks/use-masterdata";
import moment from "moment";
import ExcelJS from "exceljs";
import { FileExcelOutlined } from "@ant-design/icons";

export default function UserLeads() {
  const user = useUser();
  const [data, setData] = useState<LeadType[]>([]);
  const [masterData, refreshMasterData] = useMasterData2(
    user?.IsSupervisor
      ? "Users"
      : user?.IsManager
      ? "Supervisors"
      : "Organizations"
  );

  const [searchedText, setSearchedText] = useState("");
  const { get } = useFetch();
  const { userId } = useQuery();
  useEffect(function () {
    refreshData({ userId });
  }, []);
  async function refreshData(filter?: any) {
    let endPoint = "my";
    if (user?.IsSuperAdmin || user?.IsAdmin) {
      endPoint = "";
    }
    if (user?.IsManager) {
      endPoint = `org/${user?.OrganizationId}`;
    }
    if (user?.IsSupervisor) {
      endPoint = `org/${user?.OrganizationId}/by-team`;
    }

    const resp = await get(`/leads/${endPoint}`, {
      query: filter,
    });

    if (resp) {
      setData(resp as LeadType[]);
    }
  }
  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchedText(e.target.value);
  };

  const columns: ColumnsType<LeadType> = [
    {
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.FirstName)
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          String(record.ContactNumber)
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          String(record.Email)
            .toLowerCase()
            .includes(value.toString().toLowerCase()) ||
          String(record.CreatedDate)
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        );
      },
      title: "Name",
      dataIndex: "FirstName",
      key: "FirstName",
      render: (text, { FirstName, LastName }) => (
        <>
          {FirstName} {LastName}
        </>
      ),
    },
    {
      title: "Contact Number",
      dataIndex: "ContactNumber",
      key: "ContactNumber",
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      sorter: (a, b) =>
        moment(a.CreatedDate).unix() - moment(b.CreatedDate).unix(),
      render: toLocalDateTime,
    },
  ];

  const searchUsers = (data: any) => {
    refreshData({
      ...data,
      dateFrom: data?.dateFrom?.startOf("day").toISOString() || undefined,
      dateTo: data?.dateTo?.endOf("day").toISOString() || undefined,
    });
  };

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Users");
    sheet.columns = [
      {
        header: "Name",
        key: "name",
      },
      {
        header: "Phone Number",
        key: "phone",
      },
      {
        header: "Created Date",
        key: "createdDate",
      },
    ];
    data.map((obj) => {
      sheet.addRow({
        name: obj.FirstName,
        phone: obj.ContactNumber,
        createdDate: obj.CreatedDate,
      });
    });
    workbook.csv.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "text/csv;charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "Leads " + new Date().toDateString() + ".csv";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  function handelFilterChange(data: any) {
    if (data)
      if (data.hasOwnProperty("orgId")) {
        refreshMasterData("Supervisors", data);
      } else if (data.hasOwnProperty("supervisorId")) {
        refreshMasterData("Users", data);
      }
  }
  const [filterFormKey, setFilterFormKey] = useState<number>();

  return (
    <>
      <Card>
        <Row>
          <Col span={24}>
            <AppForm
              masterData={masterData}
              fields={filterFields}
              onSubmit={searchUsers}
              submitText="Search"
              onValuesChange={handelFilterChange}
              key={filterFormKey}
              reset={() => {
                setFilterFormKey(Date.now());
              }}
            />
          </Col>
          <Col
            span={24}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Input.Search
              onChange={onSearchChange}
              placeholder="Search"
              style={{ maxWidth: "250px" }}
            />
            <Tooltip title="Export to CSV">
              <Button
                style={{
                  marginLeft: "10px",
                  color: "White",
                  background: "#075400",
                }}
                onClick={exportExcelFile}
                type="primary"
                shape="circle"
                icon={<FileExcelOutlined />}
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </Card>
      <AppDataTable<LeadType> rowKey="Id" data={data} columns={columns} />
    </>
  );
}

const filterFields: FieldsProps[] = [
  {
    name: "orgId",
    label: "Organization",
    type: FieldTypes.select,
    hidden: (_, { user }) => !user?.IsSuperAdmin && !user?.IsAdmin,
    getOptions: ({ Organizations }: any) =>
      Organizations &&
      Organizations.map((x: any) => ({
        label: x.Name,
        value: x.Id,
      })),
  } as AppSelectProps,
  {
    name: "supervisorId",
    label: "Supervisor",
    type: FieldTypes.select,
    hidden: (_, { user }) =>
      !user?.IsSuperAdmin && !user?.IsAdmin && !user?.IsManager,
    getOptions: ({ Supervisors }: any) =>
      Supervisors &&
      Supervisors.map((x: any) => ({
        label: x.FirstName,
        value: x.Id,
      })),
  } as AppSelectProps,
  {
    name: "userId",
    label: "User",
    type: FieldTypes.select,
    hidden: (_, { user }) =>
      !user?.IsSuperAdmin &&
      !user?.IsAdmin &&
      !user?.IsManager &&
      !user?.IsSupervisor,
    getOptions: ({ Users }: any) =>
      Users &&
      Users.map((x: any) => ({
        label: x.FirstName,
        value: x.Id,
      })),
  } as AppSelectProps,
  {
    name: "dateFrom",
    label: "Date From",
    type: FieldTypes.date,
  },
  {
    name: "dateTo",
    label: "Date To",
    type: FieldTypes.date,
  },
];

type LeadType = {
  ContactNumber: string;
  CreatedDate: string;
  Email: string;
  FirstName: string;
  LastName: string;
};
