import { useEffect, useState } from "react";
import useFetch from "../../common/hooks/use-fetch";
import { Col, Row } from "antd";
import AppStatistic from "../../components/app-statistic";
import { useUser } from "../../common/root-context";

export default function OrgDashboard() {
  const user = useUser();
  const { get } = useFetch();
  const [analytics, setAnalytics] = useState<any>({});

  useEffect(function () {
    (async function () {
      const resp = await get(`/analytics/org/${user?.OrganizationId}`);
      setAnalytics(resp as any);
    })();
  }, []);
  return (
    <Row gutter={[16, 16]}>
      <Col lg={4} sm={8}>
        <AppStatistic title="All Users" value={analytics?.Users?.Total || 0} />
      </Col>
      <Col lg={4} sm={8}>
        <AppStatistic
          title="Active Users"
          value={analytics?.Users?.Active || 0}
        />
      </Col>
      <Col lg={4} sm={8}>
        <AppStatistic
          title="Total Leads"
          value={analytics?.Leads?.Total || 0}
        />
      </Col>
      <Col lg={4} sm={8}>
        <AppStatistic
          title="Last Month Leads"
          value={analytics?.Leads?.LastMonth || 0}
        />
      </Col>
    </Row>
  );
}
