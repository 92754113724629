import { ItemType } from "antd/es/menu/hooks/useItems";
import {
  LaptopOutlined,
  NotificationOutlined,
  InsertRowLeftOutlined,
  UserAddOutlined,
  BulbOutlined,
  UserOutlined,
  AppstoreOutlined,
  ShopOutlined,
  BankOutlined,
  AppstoreAddOutlined,
  ProjectOutlined,
  ShoppingOutlined,
  SettingOutlined,
  ProfileOutlined,
  UsergroupAddOutlined,
  IdcardOutlined,
  LogoutOutlined,
} from "@ant-design/icons";


const AppMenu: AppMenuItem[] = [
  {
    label: "Dashboard",
    key: "/dashboard",
    icon: <AppstoreOutlined />,
    roles: ["superadmin", "admin", "manager", "supervisor"],
  },

  {
    label: "All Users",
    key: "/users",
    icon: <UsergroupAddOutlined />,
    roles: ["superadmin", "admin", "manager", "supervisor"],
  },
  {
    label: "Leads",
    key: "/leads",
    icon: <BulbOutlined />,
    roles: [],
  },
  {
    label: "Add New Deal",
    key: "/deals/form",
    icon: <ShoppingOutlined />,
    roles: ["discountvendor"],
  },
  {
    label: "Deals",
    key: "/deals/all",
    icon: <ProjectOutlined />,
    roles: ["discountvendor"],
  },

  {
    label: "Add New Vendor",
    key: "/vendors/form",
    icon: <ShopOutlined />,
    roles: ["superadmin", "admin"],
  },
  {
    label: "Vendors",
    key: "/vendors/all",
    icon: <InsertRowLeftOutlined />,
    roles: ["superadmin", "admin"],
  },
  {
    label: "Add New User",
    key: "/user/settings",
    icon: <UserAddOutlined />,
    roles: ["superadmin", "admin"],
  },
  {
    label: "Organizations",
    key: "/organizations",
    icon: <BankOutlined />,
    roles: ["superadmin", "admin"],
  },
  {
    label: "Add Organizations",
    key: "/organization/form",
    icon: <AppstoreAddOutlined />,
    roles: ["superadmin", "admin"],
  },
  {
    label: "Edit Organization",
    key: "/org/form",
    icon: <BankOutlined/>,
    roles: ["manager"],
  },
  {
    label: "Card Plans",
    key: "/card-plan",
    icon: <IdcardOutlined />,
    roles: ["superadmin", "admin"],
  },
  {
    label: "My Profile",
    key: "/my-profile",
    icon: <UserOutlined />,
    roles: [],
  },
  {
    label: "Profile Design",
    key: "/profile-design",
    icon: <SettingOutlined />,
    roles: [],
  },
];

export type AppMenuItem = ItemType & { roles?: string[] };

export default AppMenu;

/*
{(user?.IsSuperAdmin ||
              user?.IsAdmin ||
              user?.IsManager ||
              user?.IsSupervisor) && (
              <>
                <Menu.Item key="/dashboard">
                  <AppstoreOutlined /> Dashboard
                </Menu.Item>
                <Menu.Item key="/users">
                  <UsergroupAddOutlined /> All Users
                </Menu.Item>
              </>
            )}
            <Menu.Item key="/leads">
              <BulbOutlined /> Leads
            </Menu.Item>
            {user?.IsVendor && (
              <>
                <Menu.Item key="/deals/form">
                  <ShoppingOutlined /> Add New Deal
                </Menu.Item>
                <Menu.Item key="/deals/all">
                  <ProjectOutlined /> Deals
                </Menu.Item>
              </>
            )}
            {(user?.IsSuperAdmin || user?.IsAdmin) && (
              <>
                <Menu.Item key="/vendors/form">
                  <ShopOutlined /> Add New Vendor
                </Menu.Item>
                <Menu.Item key="/vendors/all">
                  <InsertRowLeftOutlined /> Vendors
                </Menu.Item>

                <Menu.Item key="/user/settings">
                  <UserAddOutlined /> Add New User
                </Menu.Item>
                <Menu.Item key="/organizations">
                  <BankOutlined /> Organizations
                </Menu.Item>
                <Menu.Item key="/organization/form">
                  <AppstoreAddOutlined /> Add Organizations
                </Menu.Item>
              </>
            )}
            {!user?.IsAdmin && !user?.IsSuperAdmin && (
              <Menu.Item key="/my-profile">
                <UserOutlined /> My Profile
              </Menu.Item>
            )}
*/
