import { Card, Col, Layout, Row } from "antd";
import { PropsWithChildren, useState } from "react";
import {
  InstagramOutlined,
  FacebookFilled,
  GlobalOutlined,
} from "@ant-design/icons";
import "./auth-layout.css";
import loginImg from "./../images/login.gif";
import loginImgSm from "./../images/dbclogo.png";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../common/hooks/use-fetch";
const { Header, Content, Sider } = Layout;

// const curOrg = (window as any).CurOrganization as any;

export default function AuthLayout(props: PropsWithChildren<any>) {
  const params = useParams();
  const orgSlug = params["*"]?.split("/")[0];
  return (
    <>
      <div className="form-shape-wrapper">
        <div className="form-shape">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
            <path
              fill="red"
              d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z"
            ></path>
          </svg>
        </div>
      </div>
      <Layout>
        <Content
          style={{
            padding: "30px 60px",
            // paddingTop: 40,
          }}
        >
          <Card
            style={{
              position: "relative",
              borderRadius: "50px",
              boxShadow: "0 6px 15px rgba(0,0,0,.16)",
            }}
          >
            <Row>
              <Col md={12} style={{ width: "100%" }}>
                <Row style={{ flexDirection: "column", width: "100%" }}>
                  <Col style={{ alignSelf: "center" }}>
                    <SafeImg
                      src={
                        BaseUrl +
                        "/content/uploads/orgs/" +
                        orgSlug +
                        "/logo.png"
                      }
                      fallBackSrc={loginImg}
                      alt="login"
                      style={{ maxHeight: "55px" }}
                    />
                  </Col>
                  <Col md={24} xs={0}>
                    <img src={loginImg} alt="login" style={{ width: "100%" }} />
                  </Col>
                </Row>

                {/* </Col>
              <Col md={0} className="org-logo-container-sm"> */}
                {/* <SafeImg
                  src={
                    BaseUrl + "/content/uploads/orgs/" + orgSlug + "/logo.png"
                  }
                  fallBackSrc={loginImgSm}
                  alt="login"
                /> */}
              </Col>
              <Col md={12} style={{ width: "100%" }}>
                {props.children}
                <div className="text-divider">Follow Us</div>
                <div
                  className="social-links"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "25px",
                  }}
                >
                  <a href="https://www.instagram.com/DigitalBusinessCard.pk">
                    <InstagramOutlined /> Instagram
                  </a>
                  <a href="https://www.facebook.com/DigitalBusinessCard.pk">
                    <FacebookFilled /> Facebook
                  </a>
                  <a href="https://digitalbusinesscard.pk/">
                    <GlobalOutlined /> Website
                  </a>
                </div>
              </Col>
            </Row>
          </Card>
        </Content>
      </Layout>
    </>
  );
}

function SafeImg({ src, fallBackSrc, ...props }: any) {
  const [imgSrc, setImgSrc] = useState<any>(src);
  return (
    imgSrc && <img onError={() => setImgSrc(null)} src={imgSrc} {...props} />
  );
}
