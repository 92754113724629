import { useEffect, useState } from "react";
import useFetch from "../../common/hooks/use-fetch";
import AppDataTable from "../../components/data-table/app-data-table";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { toLocalDateTime } from "../../common/utils";
import { Button, Col, Dropdown, Form, Input,MenuProps, Row, Space } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  MenuOutlined
} from "@ant-design/icons";

export default function CardPlanForm() {
  const [data, setData] = useState<any>([]);
  const { post, get } = useFetch();
  const [form] = Form.useForm();

  async function savePlan(data: any) {
    await post(data.Id ? "/cardplans/edit" : "/cardplans/create", data);
    getPlans();
  }

  async function getPlans() {
    const temp = await get("/cardplans");
    if (temp) setData(temp as PlanType);
  }
  
  function handleReset() {
    form.setFieldValue("Id", null);
    form.setFieldValue("Name", null);
    form.setFieldValue("Price", null);
    form.setFieldValue("Duration", null);
  }

  useEffect(() => {
    getPlans();
  }, []);

  const columns: ColumnsType<PlanType> = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
    },
    {
      title: "Duration of Plan",
      dataIndex: "Duration",
      key: "Duration",
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      key: "CreatedDate",
      sorter: (a, b) => moment(a.CreatedDate).unix() - moment(b.CreatedDate).unix(),
      render: toLocalDateTime,
    },
    {
      title: "Action",
      dataIndex: "Id",
      key: "Id",
      render: (_, record) => <Actions row={record} />,
    },
  ];

  function Actions({ row }: { row: PlanType }) {
    async function handleClick({ key, ...e }: any) {
      if (key === "Edit"){
        form.setFieldValue("Id", row.Id);
        form.setFieldValue("Name", row.Name);
        form.setFieldValue("Price", row.Price);
        form.setFieldValue("Duration", row.Duration);
      }
      else if (key === "Delete"){
        await post("/cardplans/delete", row);
        getPlans();
      }
    }
    return (
      <Space size="middle">
        <Dropdown
          menu={{
            items: items,
            onClick: handleClick
          }}
          trigger={["click"]}
        >
          <MenuOutlined />
        </Dropdown>
      </Space>
    );
  }

  return <>
    <Row gutter={[20, 20]}>
      <Col span={24}>
        {/* <AppForm fields={FormFields} defaultData={defValues} onSubmit={savePlan} /> */}
        <Form form={form} layout="vertical" onFinish={savePlan}>
          <Row gutter={[16, 0]}>
            <Col xs={24} md={12} lg={6}>
              <Form.Item label="ID of the Plan" name="Id" >
                <Input disabled placeholder="Id of Plan"></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item label="Name of Plan" name="Name" rules={[{ required: true, message: "Please input Card Plan Name!" }]}>
                <Input type="text" placeholder="Standard"></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item label="Price of Plan" name="Price" rules={[{ required: true, message: "Please input Card Plan Price!" }]}>
                <Input type="number" placeholder="3000"></Input>
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item label="Duration" name="Duration" rules={[{ required: true, message: "Please input Card Plan Duration!" }]}>
                <Input type="number" placeholder="Number of Days"></Input>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: "8px",
          }}>
            <Button style={{ marginRight: "8px" }} htmlType="button" onClick={handleReset}> Reset</Button>
            <Button htmlType="submit" type="primary">Save</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col span={24}>
        <AppDataTable data={data} columns={columns} rowKey={data.Id} />
      </Col>
    </Row>
  </>;
}

/* const FormFields: FieldsProps[] = [
  {
    name: "Name",
    label: "Name",
    placeholder: "Plan Name",
    type: FieldTypes.text,
    rules: [{ required: true, message: "Please input Card Plan Name!" }],
  } as TextboxProps,
  {
    name: "Price",
    label: "Price",
    placeholder: "100.00",
    type: FieldTypes.text,
    rules: [{ required: true, message: "Please input Ntag!" }],
  } as TextboxProps,
]; */
const items: MenuProps["items"] = [
  {
    label: "Edit Plan",
    key: "Edit",
    icon: <EditOutlined />,
  },
  {
    label: "Delete Plan",
    key: "Delete",
    icon: <DeleteOutlined />,
  },
];

type PlanType = {
  Id: number;
  Name: string;
  Price: number;
  Duration: number;
  CreatedDate: Date;
  UpdatedDate: Date;
};