import { Input, Form, DatePicker } from "antd";
import { FieldsProps } from "./app-form";

export default function AppDateTime({
  children,
  label,
  name,
  type,
  rules,
  hidden,
  ...props
}: React.PropsWithChildren<TextboxProps>) {
  return (
    <Form.Item name={name} label={label} rules={rules}>
      <DatePicker {...props} style={{ width: "100%" }} />
    </Form.Item>
  );
}

export interface TextboxProps extends FieldsProps {
  placeholder: string;
}
