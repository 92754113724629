import { Col, Form, Row, ColorPicker, Button, Select } from "antd";
import "./view-theme-form.css"
import { useEffect, useState } from "react";
import { WhatsAppOutlined } from "@ant-design/icons";
import useFetch, { BaseUrl, useError, useSuccess } from "../../common/hooks/use-fetch";
import { useUser } from "../../common/root-context";
import { Address } from "../user/user-profile-form";

export default function ViewThemeForm() {
    const { get, post } = useFetch();
    const [form] = Form.useForm();
    const user = useUser();
    const [data, setData] = useState({} as ProfileDesign);
    const [colors, setColors] = useState({} as ProfileDesign);
    const [profile, setProfile] = useState({} as ProfileView);
    const success = useSuccess();
    const error = useError();

    async function refreshData() {
        const temp = await get(`/profile/profile-design?userId=${user?.Id}`) as any;
        if (temp) {
            setProfile(temp as ProfileView);
            setColors(temp.ProfileDesign as ProfileDesign);
            setData(temp.ProfileDesign as ProfileDesign);
        }
    }
    function GenerateAddress() {
        const add1 = profile.Addresses?.find(x => x.City);
        var address = [add1?.Line1, add1?.Line2, add1?.City, add1?.Country].filter(s => s).join(", ");
        setProfile(prevState => {
            return { ...prevState, Address: address }
        });
    }
    useEffect(() => {
        refreshData();
    }, []);

    useEffect(() => {
        form.resetFields();
        GenerateAddress();
    }, [data])

    function handleChange(data: any) {
        if (data) {
            if (data.hasOwnProperty("FontFamily")) {
                setColors({ ...colors, FontFamily: data.FontFamily })
            }
            if (data.hasOwnProperty("BackgroundColor")) {
                setColors({ ...colors, BackgroundColor: data.BackgroundColor.toHexString() })
            }
            if (data.hasOwnProperty("PhotoBorder")) {
                setColors({ ...colors, PhotoBorder: data.PhotoBorder.toHexString() })
            }
            if (data.hasOwnProperty("TaglineColor")) {
                setColors({ ...colors, TaglineColor: data.TaglineColor.toHexString() })
            }
            if (data.hasOwnProperty("DesignationColor")) {
                setColors({ ...colors, DesignationColor: data.DesignationColor.toHexString() })
            }
            if (data.hasOwnProperty("DescriptionColor")) {
                setColors({ ...colors, DescriptionColor: data.DescriptionColor.toHexString() })
            }
            if (data.hasOwnProperty("FilesLabelColor")) {
                setColors({ ...colors, FilesLabelColor: data.FilesLabelColor.toHexString() })
            }
            if (data.hasOwnProperty("FormLabelColor")) {
                setColors({ ...colors, FormLabelColor: data.FormLabelColor.toHexString() })
            }
            if (data.hasOwnProperty("ContactButtonColor")) {
                setColors({ ...colors, ContactButtonColor: data.ContactButtonColor.toHexString() })
            }
            if (data.hasOwnProperty("WhatsappButtonColor")) {
                setColors({ ...colors, WhatsappButtonColor: data.WhatsappButtonColor.toHexString() })
            }
            if (data.hasOwnProperty("LeadSubmitButtonColor")) {
                setColors({ ...colors, LeadSubmitButtonColor: data.LeadSubmitButtonColor.toHexString() })
            }
            if (data.hasOwnProperty("ContactTextColor")) {
                setColors({ ...colors, ContactTextColor: data.ContactTextColor.toHexString() })
            }
            if (data.hasOwnProperty("WhatsappTextColor")) {
                setColors({ ...colors, WhatsappTextColor: data.WhatsappTextColor.toHexString() })
            }
            if (data.hasOwnProperty("LeadSubmitTextColor")) {
                setColors({ ...colors, LeadSubmitTextColor: data.LeadSubmitTextColor.toHexString() })
            }
            if (data.hasOwnProperty("SocialLinksColor")) {
                setColors({ ...colors, SocialLinksColor: data.SocialLinksColor.toHexString() })
            }
        }
    }


    async function handleFinish() {
        if (!user?.Plan.includes("Pro")) {
            error("You are not authorized to use this feature");
            return;
        }
        if (colors.Id == 0) {
            setColors({ ...colors, Id: undefined });
        }
        await post("/profile/update-profile-design", { ...colors, UserId: user?.Id });
        success("Profile Design Updated Successfully.");
    }

    return <>
        {!user?.Plan?.includes("Pro") && !(user?.IsSuperAdmin || user?.IsAdmin) ?
            (<Row gutter={[20, 20]}>
                <Col span={24}
                    style={{ textAlign: "center", color: "rgb(0,48,94,79%)" }}
                    hidden={user?.IsSuperAdmin ||
                        user?.IsAdmin}>
                    <h1>You need to upgrade to Pro Plan to use this feature.</h1>
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                    <Button
                        href="https://wa.me/923356656657?text=I'm%20inquiring%20about%20plan%20upgradation"
                        target="_blank"
                        type="primary"
                        icon={<WhatsAppOutlined />}
                        style={{ backgroundColor: "#128c7e", borderRadius: "4px" }}>
                        Upgrade to Pro Plan
                    </Button>
                </Col>
            </Row>) :
            (<Row gutter={[20, 20]} >
                <Col lg={12} sm={18} xs={24}>
                    <Form onValuesChange={handleChange} form={form} onFinish={handleFinish} initialValues={colors as ProfileDesign}>
                        <Form.Item name={"FontFamily"} label="Font Family:">
                            <Select style={{ maxWidth: 200 }} options={options} />
                        </Form.Item>
                        <Form.Item name={"BackgroundColor"} label="Background Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"PhotoBorder"} label="Border of Profile Picture">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"TaglineColor"} label="Tagline/Name Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"DesignationColor"} label="Designation/Company Name/Address Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"DescriptionColor"} label="Description Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"ContactButtonColor"} label="Add to Contacts Button Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"ContactTextColor"} label="Add to Contacts Text Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"WhatsappButtonColor"} label="Exchange Contact Button Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"WhatsappTextColor"} label="Exchange Contact Text Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"SocialLinksColor"} label="Social Links Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"FilesLabelColor"} label="Pictures/Videos Labels Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"FormLabelColor"} label="Textbox's Border/Text Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"LeadSubmitButtonColor"} label="Get In Touch Button Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item name={"LeadSubmitTextColor"} label="Get In Touch Text Color">
                            <ColorPicker showText />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Save Settings</Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col lg={12} sm={18} xs={24}>
                    <div className="body" style={{ backgroundColor: colors.BackgroundColor, border: "1px solid black", fontFamily: colors.FontFamily }}>
                        <div className="content" >
                            <section >
                                <div className="container" >
                                    <div className="bg-gray p-2 rounded" style={{ backgroundColor: colors.BackgroundColor }}>
                                        <div className="top-section" >
                                            <figure className="profile-img"><img src={BaseUrl + profile.Photo} style={{ border: colors.PhotoBorder && "5px solid " + colors.PhotoBorder }} className="circle-rounded" /></figure>
                                        </div>
                                        <div className="text-center first-section">
                                            <div className="desegnation">
                                                <h5 style={{ color: colors.TaglineColor || "gold", fontSize: "x-large", marginBottom: "-18px" }} id="tagline">{profile.Tagline || "Hi, I'm"} <span className="profile-name">{profile.FirstName}</span></h5>
                                                <h6 style={{ color: colors.DesignationColor, fontSize: "12px", marginBottom: "-13px" }} id="designationHeading"><b> <span className="profile-designation">{profile.Designation ? (profile.Designation + " at " + (profile.OrganizationName || user.OrganizationName)) : "CEO at Dbc"}</span></b></h6>
                                                <h6 style={{ color: colors.DesignationColor, fontSize: "12px", marginBottom: "0px" }}><b><i className=""></i> <span className="profile-city">{profile.Address || "Islamabad"}</span></b></h6>
                                            </div>
                                            <p style={{ color: colors.DescriptionColor || "#ebebeb", lineHeight: "16px" }} className="text-center" id="description">{profile.Description || "We aim to eliminate paper business cards and the associated waste and pollution they cause. Our smart digital business cards empower better business connections across the globe and give you an opportunity to do your part to save the planet."}</p>
                                        </div>
                                        <div className=" ps-0 pe-0 d-flex justify-content-center">
                                            <a className="btn btn-orange btn-small btn-save" style={{ color: colors.ContactTextColor, backgroundColor: colors.ContactButtonColor, marginRight: "5px", fontWeight: "700 !important" }}>
                                                <i className="fa fa-address-book" style={{ fontSize: "12.5px", fontWeight: "700" }}></i> Add to Contacts
                                            </a>
                                            <a className="btn btn-orange btn-sm btn-wa" style={{ color: colors.WhatsappTextColor, backgroundColor: colors.WhatsappButtonColor, marginLeft: "5px", fontWeight: "700 !important" }}>
                                                <i className="fab fa-whatsapp" style={{ fontSize: "12.5px", fontWeight: "700" }}></i> Exchange Contact
                                            </a>
                                            {/* <a className="btn btn-orange btn-sm btn-wab" style="display:none; margin-left:5px;background:#02b945;font-weight:700 !important;">
                                <i className="fab fa-whatsapp" style="font-size:12.5px;font-weight:700"></i> WhatsApp Business
                            </a> */}
                                        </div>
                                        <div className="social-icon links" style={{ color: colors.SocialLinksColor, fill: colors.SocialLinksColor }} >
                                            <span className="profile-link insta" data-pf="Instagram">
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: colors.SocialLinksColor }} viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                                                <span style={{ display: "block" }}>Instragram</span>
                                            </span>
                                            <span className="profile-link tt" data-pf="TikTok">
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: colors.SocialLinksColor }} viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" /></svg>
                                                <span style={{ display: "block" }}>Tiktok</span>
                                            </span>
                                            <span className="profile-link fb" data-pf="Facebook">
                                                <svg xmlns="http://www.w3.org/2000/svg" style={{ fill: colors.SocialLinksColor }} viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
                                                <span style={{ display: "block" }}>Facebook</span>
                                            </span>
                                            <span className="profile-link lkdin" data-pf="Linkedin">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" className="bi bi-linkedin" viewBox="0 0 16 16"> <path style={{ fill: colors.SocialLinksColor }} d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" fill="white"></path> </svg>
                                                <span style={{ display: "block" }}>Linkedin</span>
                                            </span>
                                        </div>
                                        <div className="grid-post-wrap profile-banners" >
                                            <h5 style={{ textAlign: "left", marginBottom: "3px", fontSize: "17px", color: colors.FilesLabelColor || "#add8e6" }}>Image Gallery</h5>
                                            {/* <!--grid-post-item--> */}
                                            <div className="single-grid-slider-wrap fl-wrap">
                                                <div className="single-grid-slider">
                                                    <div className="swiper-container">
                                                        <div className="swiper-wrapper lightgallery profile-banners-container">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!--grid-post-item end--> */}
                                        </div>
                                        <div className="fl-wrap profile-videos" >
                                            <h5 style={{ textAlign: "left", marginBottom: "5px", fontSize: "17px", color: colors.FilesLabelColor || "#add8e6" }}>Have a Look on our Video Highlights</h5>
                                            <div className="video_carousel-container">
                                                {/* <!-- fw-carousel  --> */}
                                                <div className="video_carousel  lightgallery">
                                                    <div className="swiper-container">
                                                        <div className="swiper-wrapper  profile-videos-container">
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- fw-carousel end --> */}
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                        <h5 id="formLeadTitle" style={{ textAlign: "left", marginBottom: "5px", fontSize: "17px", color: colors.FilesLabelColor || "#add8e6" }}>
                                            <span className="msg-1">
                                                Enter your details to stay in touch!
                                            </span>
                                        </h5>
                                        <div className="fl-wrap">
                                            <form id="formLead" className="fl-wrap" noValidate>
                                                <input className="mb-2" name="FirstName" placeholder="Your Name" spellCheck="false" type="text" style={{ border: colors.FormLabelColor ? `1px solid ${colors.FormLabelColor}` : "1px solid rgba(255, 255, 255, 0.1)", color: colors.FormLabelColor }} />
                                                <input className="mb-2" name="ContactNumber" placeholder="Mobile Number" spellCheck="false" type="text" style={{ border: colors.FormLabelColor ? `1px solid ${colors.FormLabelColor}` : "1px solid rgba(255, 255, 255, 0.1)", color: colors.FormLabelColor }} />
                                                <input name="UserId" type="hidden" />
                                                <button type="button" style={{ color: colors.LeadSubmitTextColor, backgroundColor: colors.LeadSubmitButtonColor }} className="subscribe-button color-bg">Get in touch! </button>
                                            </form>
                                        </div>
                                        <div className="clearfix"></div>
                                        <p id="orderLink" className="mt-2 p-2 border mb-2" style={{ textAlign: "center", fontSize: "15px", color: colors.FilesLabelColor || "#ebebeb" }}>
                                            Order your Digital Business Card Now
                                        </p>
                                        <div className="clearfix"></div>
                                        <p className="text-center mb-0 pb-0" style={{ color: colors.DescriptionColor || "#ebebeb" }}>© Copyrights 2023. Powered by Digital Business Card</p>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </Col>
            </Row>)
        }
    </>
}
const options = [
    {
        label: "Quicksand, Sans-Serif",
        value: "\"Quicksand\", sans-serif"
    },
    {
        label: "Times New Roman",
        value: "times new roman"
    },
    {
        label: "Arial",
        value: "arial"
    },
    {
        label: "Calibri",
        value: "calibri"
    },
    {
        label: "Serif",
        value: "serif"
    },
    {
        label: "Sans-Serif",
        value: "sans-serif"
    },
    {
        label: "System-Ui",
        value: "system-ui"
    },
    {
        label: "Monospace",
        value: "monospace"
    },
    {
        label: "Cursive",
        value: "cursive"
    },
];
export type ProfileView = {
    FirstName: string;
    LastName: string;
    Tagline: string;
    OrganizationName: string;
    Designation: string;
    Description: string;
    Photo: string;
    Addresses: Address[];
    Address: string;
};
export type ProfileDesign = {
    Id?: number;
    ProfileId: number;
    FontFamily: string;
    BackgroundColor: string;
    PhotoBorder: string;
    TaglineColor: string;
    DesignationColor: string;
    DescriptionColor: string;
    SocialLinksColor: string;
    FilesLabelColor: string;
    FormLabelColor: string;
    ContactButtonColor: string;
    ContactTextColor: string;
    WhatsappButtonColor: string;
    WhatsappTextColor: string;
    LeadSubmitButtonColor: string;
    LeadSubmitTextColor: string;
};